<template>

  <div id="RadialOptions_body">

    <loader v-if="showLoader" />

    <nav class="circular-menu">
      
      <!--Coberturas Rectangulo-->
      <!-- <div class="coverages"
        v-if="selectedMenu && currentState.id == 1"
      >
        <div class="title">
          {{ selectedMenu.title_2.join(" ") }}
        </div>
        <ul class="info">
          <li
            v-for="coverage in selectedMenu.title_2_description"
            :key="coverage"
          >
            {{ coverage }}
          </li>
          <div id="more_about_radial" style="z-index:1;" @click="openManual">Saber Más</div>
        </ul>
      </div> -->

      <!--MONITO Y RECUADRO VERDE-->
      <!-- <div class="pet_phrase"
        v-if="selectedMenu && currentState.id == 1"
      >
        <p class="title">{{selectedMenu.avatar_text}}</p>
        <img
          :src="selectedMenu.image_xl_secondary"
          alt=""
        />
        <div class="" v-if="selectedMenu && currentState.id == 1"></div>
      </div> -->


      <div class="circle">

        <radial-item
        v-for="(item , index) in menuListData"
        :key="'menu_item_' + index"
        :item="item"
        :is_selected="item.selected"
        style="transform: scale(1);"
        @mouseover.native="setCurrentSection(item)"
        @click.native="
          item.selected = !item.selected;
          updateSelected(item);
        ">
        </radial-item>

      </div>

      <!-- CIRCULO CENTRAL -->
      <div class="center_radial">

        <div class="main_container"
          v-if="selectedMenu && currentState.id == 1"
          :key="selectedMenu.id"
        >

          <div class="promotional_container">
            <p>DESDE</p>
            <p>
              MX$<span class="resaltive-text">{{ selectedMenu.title_1_attractive_payment }}</span>/{{ selectedMenu.title_1_payment_frecuency }}
            </p>
            <p v-if="selectedMenu.id != 7" class="mini-text">PAGANDO ANUALMENTE</p>
          </div>

          <div class="general_info">

            <p
              class="title"
              v-for="(rowText, index) in selectedMenu.title_1"
              :key="'title' + index"
            >
              {{ rowText }}
            </p>

            <p
              class="subtitle"
              v-for="(rowText, index) in selectedMenu.title_1_description"
              :key="'subtitle' + index"
            >
              {{ rowText }}
            </p>

          </div>

        </div>

      </div>

    </nav>  

    <div class="circular-footer">
      
      <transition name="fade-expand">
        <div
          class="select_options"
          v-if="!selectedItems.length && currentState.id == 1"
        >
          <h3>Selecciona algunas opciones de cotización</h3>
        </div>
      </transition>

      <div
        class="selected_insurances"
        v-if="selectedItems.length && [1, 2].includes(currentState.id)"
      >
        <transition-group
          name="list-complete"
          tag="p"
          class="items"
        >
          <div
            v-for="item in selectedItems"
            :key="'selected-' + item.id"
          >
            <img
              :src="item.image_xl_primary"
              :width="sizeOfChoicedItems.w"
              :height="sizeOfChoicedItems.h"
            />
          </div>
        </transition-group>
      </div>
      

        
      <div class="quoting"
        v-if="selectedItems.length && currentState.id == 1"
      >

          <!-- HERE CHANGES DINAMICINACTIVE                                                                                         -->
        <button id="emit_button" class="rounded-md focus:outline-none border-2 border-primary btn-special-1 w-full sm:w-auto px-10"
          @click="clickBacklog('quote_radial_view');"
            v-if="validateInactiveInsurances()"
            >
          Cotizar
          <font-awesome-icon
            :icon="['fas', 'arrow-right']"
            class="fa-1x absolute right-4 animate-goto"
          />
        </button>            

        
        <div v-else id="mailto_container">

          <h1>Este seguro requiere mayor informacióń, iniciemos conversación</h1>
          <h1>*Escribe tus Datos de Contacto</h1>

          <h1 class="exit" @click="close_mailto_container();">
          x
          </h1>

          <div id="mailto_container">
  
            <svg id="email_message_button" class="bi bi-envelope" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
            </svg>

            <input type="text" placeholder="Escribe un mensaje"
              v-model="message_mailto_data"
            >

            <!-- WORKING ON IT -->
            <a :href="'mailto:notificaciones@sabar.mx?subject=' + 'Seguros Interes:' + selectedProductCategoriesNames + '         Cliente:' + message_mailto_data">
              <font-awesome-icon
              :icon="['fas', 'paper-plane']"
              @click="send_message_mailto();"
            />
            </a>

          </div>

        </div>


      </div>

    </div>

  </div>
</template>


<script>

  import RadialItem from "./RadialItem.vue"
  import { mapGetters } from "vuex"
  import Loader from "@/components/Loader.vue"

  export default {
    name: "radialOptions",
    data: () => ({

      showLoader: false,
      selectedProductCategoriesNames:'',
      inactive_insurances : [],
      window: {
        width: 0,
        height: 0
      },
      choicedItemsSize: { w: 120, h: 120 },
      menuList: [],
      message_mailto_data:'',
      selectedMenu: null,
      applyDelay: true, // Activa un delay para aplicarlo al iniciar la expansión del menú
      elipseWRadius: 300, // Define el ancho del menú
      paddingCircleClasses: [
        "pl-0",
        "pl-1",
        "pl-3",
        "pl-6",
        "pl-12",
        "pl-20",
        "pl-28"
      ],
      currentState: {
        id: 0,
        description:
          "El componente está en una escala de 0, por lo que no se muestra aún."
      },
      componentStates: {
        0: {
          id: 0,
          description:
            "El componente está en una escala de 0, por lo que no se muestra aún."
        },
        1: {
          id: 1,
          description:
            "El componente se muestra activando la animación de inicio."
        },
        2: {
          id: 2,
          description:
            "El componente del menu radial se reduce, pero no los botónes de items seleccionados."
        },
        3: {
          id: 3,
          description: "Se desaparecen los indicadores de items seleccionados."
        }
      },

    }),
    components: { 
      RadialItem,
      Loader 
    },
    props: ["elipseHRadius", "stepProgress"],
    computed: {
      ...mapGetters(["productCategories"]),
      elipsisHeight() {
        switch (this.currentState.id) {
          case 0:
            return this.elipseHRadius;
          case 1:
            return this.elipseHRadius;
          case 2:
            return 0;
          default:
            return 0;
        }
      },
      selectedItems() {
        return this.menuList.filter(item => item.selected == true);
      },
      selectedProductCategories() {
        try {
          var response = this.productCategories.getUserSelection();
          return response 
        } catch (error) {
          return []
        }
      },
      sizeOfChoicedItems() {
        // El tamaño de los items depende de la cantidad de items seleccionados, más items seleccionados reducen el tamaño de los mismos
        let sizeModificator = 0.99 ** (this.selectedItems.length * 10);
        // console.log(sizeModificator);
        return {
          w: (this.choicedItemsSize.w * sizeModificator).toFixed(2),
          h: (this.choicedItemsSize.h * sizeModificator).toFixed(2)
        };
      },
      menuListData() {
        let self = this;
        let pi = Math.PI;
        let stepDegree = (2 * pi) / self.menuList.length; // Obtenemos el angulo de giro de separación entre cada elemento
        let responseData = []; // Array de elementos modificados a devolver
        let pi_part = pi / 2 / self.menuList.length;
        let addToAngle = self.menuList.length % 2 == 0 ? 0 : pi_part; // Un ajuste de angulo para mostrar las opciones de forma simetrica vertical
        let efectiveRadius = self.elipseHRadius; // El radio normal es el máximo disponible, pero si colocamos un item justo en el limite del radio, este por su tamaño excederá el circulo, por eso el radio efectivo debe ser menor
        // Los elementos se situaran en un centro, y a partir de ahí, cada elemento se trasladará en los ejes X y Y para obtener su posición final
        self.menuList.forEach((item, index) => {
          if (
            !self.currentState ||
            self.currentState.id == 0 ||
            self.currentState.id == 2
          ) {
            item.x = 0;
            item.y = 0;
            item.style =
              "transform: scale(0) translate(" +
              item.y +
              "px, " +
              item.x +
              "px);";
          } else if (self.currentState.id == 1) {
            item.x = efectiveRadius * Math.sin(addToAngle + index * stepDegree);
            item.y = efectiveRadius * Math.cos(addToAngle + index * stepDegree);
            // console.log('Antes de Imprimir las cordenadas x,y')
            // console.log(efectiveRadius * Math.sin(addToAngle + index * stepDegree))
            // console.log(efectiveRadius * Math.cos(addToAngle + index * stepDegree))
            
            item.style =
              "transform: scale(1) translate(" +
              0 +
              "px, " +
              0 +
              "px);";
            if (self.applyDelay) {
              item.style += "transition-delay:" + Math.cos(pi_part * index) + "s";
            }
          }
          responseData.push(item);
        });
        return self.menuList;
      }
    },
    mounted() {
      this.showLoader = true
      setTimeout(() => {
        this.setInitialState()
        this.settleRadialElements()
        this.showLoader = false
      }, 2000)
    },
    destroyed() {
      window.removeEventListener("resize", this.handleResize);
    },
    methods: {
      start() {
        // console.log("Comenzando la animación de entrada");
        let self = this;
        setTimeout(() => {
          self.currentState = self.componentStates[1];
          // console.log("MenuList", self.menuList);
        }, 1000);
      },
      end() {

        setTimeout(() => {
          this.$emit("next");
        }, 1000);

      },
      setInitialState() {
        let self = this;
        let productCategories = this.productCategories.getItems();
        // console.log(productCategories)

        // Veryfying Active Insurances
        productCategories.forEach(product => {
          if(!product.is_active){
            this.inactive_insurances.push(product.id)
          }
          // var is_active = (product.id, product.is_active)
        })

        // Important Comment
        // console.log(JSON.stringify(this.inactive_insurances))

        self.menuList = productCategories.map(function(item) {
          return {
            ...item,
            selected: self.selectedProductCategories.filter(x => x.id == item.id)
              .length,
            x: 0,
            y: 0,
            style: ""
          };
        });

        window.addEventListener("resize", self.handleResize);
        self.handleResize();
      },

      // HERE CHANGES DINAMICINACTIVE
      close_mailto_container(){

        var inactive_insurances = this.inactive_insurances
        inactive_insurances.forEach(inactive_insurance => {
          this.menuListData[inactive_insurance].selected = false        
        });

        // this.menuListData[3].selected = false
        // this.menuListData[7].selected = false
        // this.menuListData[10].selected = false

      },
      updateSelected(item_selected) {      

        // Función Especifica para deshabilitar la selección multiple
        this.menuList.forEach((selectedInsurance,index) => {
          if(selectedInsurance.id != item_selected.id){
            this.menuListData[index].selected = false
          }
        })

        this.$emit("choiced", {
          choiced: this.menuList.filter(item => item.selected == true)
        })
        this.selectedProductCategoriesNames = ""
        this.selectedProductCategories.forEach(insurances => {
          this.selectedProductCategoriesNames += `(${insurances.name})`
        })        
      },
      send_message_mailto(){

        // HERE CHANGES DINAMICINACTIVE
        var inactive_insurances = this.inactive_insurances
        inactive_insurances.forEach(inactive_insurance => {
          this.menuListData[inactive_insurance].selected = false        
        });     

        this.selectedProductCategories.shift()
        // console.log(this.selectedProductCategories)
        this.selectedProductCategories.shift()
        // console.log(this.selectedProductCategories)
      },
      handleResize() {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;
      },
      setCurrentSection(item) {
        this.selectedMenu = item;
      },
      validateInactiveInsurances(){
        
        var response = true
        var selectedProductCategories = this.selectedProductCategories.map(item => item.id)
        // console.log(selectedProductCategories)

        var inactive_insurances = this.inactive_insurances
        inactive_insurances.forEach(inactive_insurance => {
          if(selectedProductCategories.includes(inactive_insurance)){
            response = false
          }
        });

        return response

      },
      openManual() {

        var category_id = this.selectedMenu.id
        var manuals_names = {
          // Accidentes Personales
          "1" : "media/Thona/Generales_Thona_AccidentesPersonales.pdf",

          // Bicicletas
          "2" : "media/Primeros_Seguros/Generales_PrimerosSeguros_Bicicleta.pdf",

          // Cáncer
          "3" : "media/THB_General/Folleto_Cnsf_THB.pdf",

          // COVID
          "4" : "media/Thona/Generales_Thona_Covid.pdf",

          // Dental
          "5" : "media/Dentegra/Generales_Dental_Dentegra.pdf",

          // Funerario
          "6" : "media/Thona/Generales_Thona_Funerario.pdf",

          // Gastos Médicos
          "7" : "media/THB_General/Folleto_Cnsf_THB.pdf",

          // Hogar
          "8" : "media/Chubb/Generales_Chubb_Hogar.pdf",

          // Mascotas
          "9" : "media/Gmx/Generales_Gmx_Mascota.pdf",

          // Pyme
          "10" : "media/THB_General/Folleto_Cnsf_THB.pdf",

          // Quirúrgico
          "11" : "media/Thona/Generales_Thona_Quirurgico.pdf",

          // Accidentes en Viajes
          "12" : "media/Continental/Generales_Continental_Viajero.pdf",

          // VRIM
          "13" : "media/VRIM/Generales_VRIM_Producto.pdf",
        }

        var manual_name = manuals_names[category_id]
        var path = this.$APIServices.endpoint + manual_name
        window.open( path , '_blank' );
      },

      async clickBacklog(place){
          
        var wordpress_redirect_array = this.productCategories.userSelection.filter(item => item.id == 9)

        if(wordpress_redirect_array.length != 0){

          // Validacion para URLs con redirect externo
          window.open("https://sabar.mx/seguro-mascotas/" , '_blank')
        
        } else {

          var insurance_selected = this.productCategories.userSelection[0].id
          var IdsInsurances = {
            "1":"accidentes-personales",
            "2":"bicicleta",
            "5":"dental",
            "6":"funerario",
            "8":"hogar",
            "9":"mascota",
            "11":"quirurgico",
            "12":"viajes",
            "13":"vrim",
          }

          window.open(`https://seguros.sabar.mx/seguro/${IdsInsurances[insurance_selected]}` , '_blank' );
          
          // var insurances_selected = this.productCategories.userSelection
          // var insurances_selected_text = ""
          // var insurances_selected_ids = []
          // insurances_selected.forEach(insurances_selected => {
          //   insurances_selected_text += (insurances_selected.name+",");
          //   insurances_selected_ids.push(insurances_selected.id)
          // })
          
          // var url = this.$APIEndpoint + this.$APIServices.click_watcher
          // var body = {
          //   "place": place,
          //   "comments": `Seguros Seleccionados: ${insurances_selected_text}`,
          //   "category_ids":insurances_selected_ids
          // }
          // await this.$http.post(url , body , this.$auth
          // ).then(() => {
          //   // console.log("Result:", result)
          // }).catch((error) => {
          //   console.log(error)
          // }).finally(() => {
          //   this.end()
          //   this.selectedMenu = null
          // })

        }

      },
      settleRadialElements(){

        setTimeout(() => {
          
          var items = document.querySelectorAll('.circle li');
          var first_axis = 50
          var second_axis = 70
          
          for(var i = 0, l = items.length; i < l; i++) {
            items[i].style.left = ( first_axis - second_axis * Math.cos( -0.5 * Math.PI - 2 * (1/l) * i * Math.PI )).toFixed(4) + "%";        
            items[i].style.top = ( first_axis + second_axis * Math.sin( -0.5 * Math.PI - 2 * (1/l) * i * Math.PI )).toFixed(4) + "%";
          }

        }, 500);

      }

    }
  };
</script>

<style>

  .radial-options-wraper {
    height: 100%;
  }

  .menu-data {
    background-color: #7c5ece;
    height:290px !important;
    width:290px !important;
    padding-top:10px;
  }
  
  .item-price {
    text-shadow: -4px -3px 0px #7c5ece;
    -webkit-text-stroke: 2px #7c5ece;
    -webkit-text-fill-color: white;
  }
  .disabled {
    display: none;
  }
  #mailto_container_main{
    position: absolute;
    margin-left: -220px;
    margin-top: -150px;
    background-color:#7c5ece;
    
    padding: 10px;
    border-radius: 20px;
    height: 145px;
    width: 480px;
    z-index: 20;
  }
  
  .second_text{
    font-weight: Light;
    color: white;
  }
  .unorder_list_text{
    font-size: 13px;
  }
  #mailto_container_secondary{
    width: 100%;
    display: flex;
    justify-content:center;
    align-items:center;
    margin-top: 8px;
  }
  .send_message_mailto{
    color: white;
  }
  #container_mailto_button{
    position: absolute;
    margin-left: 130px;
    margin-top: -10px;
  }
  #email_message_button{
    margin-right: 6px;
    padding:4px;
    width:25px;
    height:25px;
    background-color: #f0b30d;
    color: #fff;
    border-radius:50px;
    box-shadow: 0px 1px 10px rgba(0,0,0,0.3);
    cursor: none;
  }
  #mailto_message_input{
    width:100%;
    color: #fff;
  }
  #emit_package_selectedContainer{
    position: relative;
  }
  .radial_items{
    transform: scale(0.55) translate(0px,0px);
  }
  .informative_circle {
    font-size: 15px;
  }
  .radial_items{
    display: absolute;
  }

  .coverages_container{
    margin-top: 0px;
  }

  @media (min-width: 1024px) {
    .radial_items{
      transform: scale(0.8) translate(0px,0px);
    }
    .sps{
      display: none;
    }

    .informative_circle_title{
      width: 350px;
      border-radius: 170px;
    }    
    .coverages_container {
      position: absolute;
      margin-left:auto;
      width: 250px;
      margin-top: 0px;
      left: auto;
      margin-right:850px;
      transform: scale(0.9);
    }
    .informative_circle {
      font-size: 15px;
      width : 350px;
      height : 350px;
      border-radius: 90px;
      transform: scale(0.8);
      margin: auto;
      border-radius: 170px;
    }
    .sabar_pet {
      position:absolute;
      margin-right: -130px;
      right:auto;
      width : 290px;
      margin-left: 750px;
      right: auto;
      transform: scale(0.8);
    }
    .sabar_pet_text{
      font-size: 20px;
      width :250px;
      height :150px;
      margin-left: -100px;
      margin-bottom: -30px;
      transform: scale(1);
    }
    .sabar_pet_img{
      width :120px;
      height :120px;
      margin-bottom: -90px;
      margin-left: -160px;
    }
  }

  .promotion_tag{
    color:white;
    line-height:0px;
  }

  .promotion_tag_price{
    font-size:30px;
  }

  @media (min-width: 1024px) {
    .menu-data {
      padding-top:30px;
    }
  }

  .promotion_tag_conditions{
    font-size:10px;
  }
 
</style>