<template>
  <li
    class="RadialItem_body"
  >

    <div class="item_container"
      :class="[item.selected || item.link ? 'active' : '']"  
    >

      <img
        :src="item.image_xl_primary"
      />
      
      <div
        v-if="!item.link"
        class="checkbox_container transform"
        :class="[is_selected ? 'border-secondary' : 'border-grey-200']"
      >
        <check-button
          v-show="!item.link"
          :item="item.id"
          :is_selected="is_selected ? true : false"
          :key="'check' + item.id"
        ></check-button>
      </div>
      <div v-else>
        ¿Te puedo ayudar?
      </div>
    </div>

  </li>
</template>

<script>
import CheckButton from "../../check-button.vue";
export default {
  name: "radialOptions",
  data: function() {
    return {};
  },
  components: {
    CheckButton
  },
  props: ["item", "is_selected"],
  computed: {},
  mounted() {},
  methods: {},
  watch: {}
};
</script>